/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"THEHUB"}>
        <SiteHeader />

        <Column className="css-b3ofy0 pb--60 pt--80" name={"intro-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37641/99362f36cd454d308d86d4a2a333e22a_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--128" content={"<span style=\"color: var(--color-blend--95);\">THE HUB\n</span>"}>
              </Title>

              <Title className="title-box fs--30 swpf--uppercase" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--85);\">SPORT - NETWORK - BRANDS&nbsp;</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fvv7ovj6z0k"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37641/1d87b802bc8943939c9a1d63e6cb4562_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/1d87b802bc8943939c9a1d63e6cb4562_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/1d87b802bc8943939c9a1d63e6cb4562_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/1d87b802bc8943939c9a1d63e6cb4562_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box" content={"SPORT"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"paddingLeft":0}} content={"&nbsp;THEHUB has mentors, contacts, and partnerships in various sports. Feel free to reach out to us. We are driven by creating opportunities and possibilities."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37641/dfd2e9f0b3744f4198017cf01583c523_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/dfd2e9f0b3744f4198017cf01583c523_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/dfd2e9f0b3744f4198017cf01583c523_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/dfd2e9f0b3744f4198017cf01583c523_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box" content={"NETWORK"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":467}} content={"THEHUB network offers invaluable connections and resources for individuals and businesses alike, both in the short and long term. Don't hesitate to join THEHUB. i We're dedicated to fostering growth and success,"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37641/934e11fc2b064f19bb5fab691df1f324_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/934e11fc2b064f19bb5fab691df1f324_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/934e11fc2b064f19bb5fab691df1f324_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/934e11fc2b064f19bb5fab691df1f324_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--left" content={"BRANDS"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":467}} content={"THEHUB provides you with a unique opportunity to access offers, benefits, and products from some of Europe and the world's top brands."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footern"} style={{"marginTop":148,"paddingTop":28,"paddingBottom":34,"backgroundColor":"rgba(32, 32, 32, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--top" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">THE HUB</span>"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":806}} content={"<span style=\"color: var(--color-custom-1--95);\">Tillgängligheten 1<br>417 10 Gothenburg<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":627,"paddingBottom":0}}>
              
              <Title className="title-box title-box--center fs--18" content={"<span style=\"color: rgb(246, 246, 248);\">ADRESS</span><br>"}>
              </Title>

              <Text className="text-box text-box--center fs--12" style={{"maxWidth":269}} content={"<span style=\"color: var(--color-custom-2--95);\">THEHUB&nbsp; AB<br>559470-2820</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 btn-box--center fs--12" use={"page"} href={"/contact#contact"} style={{"maxWidth":802,"marginTop":8,"paddingTop":9}} target={null} content={"<span style=\"font-weight: bold;\">Contact&nbsp;us</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">MENU</span>"}>
              </Title>

              <Text className="text-box fs--12 w--500 ls--04 lh--14" content={"<a href=\"#\" style=\"\"><span style=\"color: var(--color-custom-1--95);\">Home</span><br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Brands<br>THE HUB<br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Contact</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}